import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
import whatsapp from "../assets/images/whatsapp.png";
import telegram from "../assets/images/telegram.png";
import livechat from "../assets/images/live-chat.png";
import support from "../assets/images/download-app.png";

const Footer = () => {
  const location = useLocation();
  const { user, showSidebar, active, setFooterLink } = useContext(AuthContext);
  const navigate = useNavigate();
  const [casinoModel, setCasinoModel] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [icon, setIcon] = useState({});
  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setStartX(touch.clientX);
    setStartY(touch.clientY);
  };

  const getData = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    const { data } = await apiGet(apiPath?.getIcon, {
      website: "xbet247.com"
    });
    if (data?.success) {
      setIcon(data?.results);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleTouchEnd = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;
    const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
    if (distance < 5) {
      setCasinoModel(true);
    }
  };
  // function handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
  //   console.log("LiveChatWidget.onNewEvent", event);
  // }
  const [showf, setshowf] = useState(false);

  return (
    <>
    <LiveChatWidget
      license="17135889"
      visibility="minimized"
      // onNewEvent={handleNewEvent}
    />
       <div className="footer">
        <div className="betbtn1"  onClick={(e) => {
                setshowf(true)
              // window.location.href = "mailto:support@cricbet10.co";
              // e.preventDefault();
              }}
              
              >
        <div>
          
                <img
                style={{borderRadius:`50%`}}
                  src={support}
                  alt=""
                />
              </div>
          </div>
          
        {location?.pathname == "/" && !showSidebar && active == "sports" && showf && (
          
          // icon?.whatsappContent!== "" || icon?.telegramContent!== "" || icon?.livechatContent!== "" ?
          <div className="betbtn1 betbtn4">
            {/* {icon?.whatsappContent && icon?.whatsappContent !== "" && ( */}
              <div
              onClick={() => {
                window.open(icon?.whatsappContent?"https://wa.me/"+icon?.whatsappContent:"https://wa.me/+19292381184", "_self");
              }}
              >
                <img
                  src={icon?.whatsappIcon?process.env.REACT_APP_API_BASE_URL + icon?.whatsappIcon:whatsapp}
                  alt=""
                />
              </div>
            {/* )} */}
            {/* {icon?.telegramContent && icon?.telegramContent !== "" && ( */}
              <div
                onClick={() => {
                  window.open(icon?.telegramContent?"https://t.me/"+icon?.telegramContent:"https://t.me/+19292381184", "_self");  
                 
                }}>
                <img
                  src={icon?.telegramIcon?process.env.REACT_APP_API_BASE_URL + icon?.telegramIcon:telegram}
                  alt=""
                />
              </div>
            {/* )}{" "} */}
            {icon?.livechatContent && icon?.livechatContent !== "" && location?.pathname == "/" && (
              <div
                onClick={() => {
                  window.open(icon?.livechatContent, "_self");
                }}
              >
                <img
                  src={icon?.livechatIcon?process.env.REACT_APP_API_BASE_URL + icon?.livechatIcon:livechat}
                  alt=""
                />
              </div>
            )}
           
          
          </div>
          // :""
          
        )}
        
      </div> 
    </>
  );
};

export default Footer;
